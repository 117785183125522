/***
*
*   LTI Integration landing page
*   
**********/

import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Animate, Card, History, useLTI, ViewContext, Button, SessionControlSettings } from 'components/lib';
import { useParams } from 'react-router-dom';

import Axios from 'axios';

const formRef = React.createRef();

export function Settings(props){
  const ltiInfo =  useLTI(true);

  const context = useContext(ViewContext);

  const searchParams = new URLSearchParams(window.location.search)
  const resource = {type: searchParams.get('type'), id: searchParams.get('id')}

  const [deepLinkData, setDeepLinkData] = useState({url: null, JWT: null})

  const {project_id} = useParams();


  useEffect(() => {
    if (ltiInfo?.error) {
      ltiInfo.handleError();
    }
  },[ltiInfo])


  async function submit(formData) {
      // Submits resource to deep linking endpoint
    try {

      
      if (!resource.type || !resource.id) {
        History.goBack();
        return
      }

      const ltik = searchParams.get('ltik')
      if (!ltik) throw new Error('Missing lti key.')

      const payload = {
        url: `/lti/${project_id}/deeplink`,
        headers: {Authorization: 'Bearer ' + ltik },
        credentials:'include',
        data: { 
          ...resource,
          ...formData
        },
        method: 'post'
      }
      
      const deepLinkingMessage = await Axios(payload);


      setDeepLinkData(deepLinkingMessage.data);
      formRef.current.submit()

      context.notification.show('Content Selected'); 
    } catch (err) {
      console.log(err)
      context.notification.show('Failed creating deep link! ' + err, 'error')
    }
  }
  
  return(
    <Fragment>
      <Animate>
      <Button icon='arrow-left' small text={`Back to Select ${resource.type}`} action={() => History.goBack() } />
        <Card >
          <SessionControlSettings
            buttonText='Submit'
            callback={(data) => submit(data.form)} />
        </Card>
        <form ref={formRef} style={{display: 'none'}} action={deepLinkData?.url} method="POST">
          <input type='hidden' name='JWT' value= {deepLinkData?.JWT || ''}/> 
        </form>
      </Animate>
    </Fragment>
  );
}
