/***
*
*   IMAGE
*   Import the image before passing it to this component
*
*   PROPS
*   source: imported source
*   alt: alt description
*   title: description
*   className: inject a custom class object
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import Style from './image.module.scss';

export function Image(props){

  const css = ClassNames([

    Style.image,
    props.className,
    props.left && Style.left,
    props.right && Style.right,
    props.center && Style.center

  ]);

  return(
    <img
      src={ props.source }
      alt={ props.alt }
      title={ props.title }
      className={ css }
      style={ props.height ? {height: props.height} : null}
    />
  );
}
