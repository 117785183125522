/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, ViewContext, Form, Card,
  Button, History, Animate, useAPI, Event } from 'components/lib';

export function Profile(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/v1/profile');

  function closeAccount(){
    viewContext.modal.show({

      title: 'Close Your Account',
      form: {},
      buttonText: 'Close Account',
      url: authContext.permission.owner ? '/v1/account' : '/v1/profile',
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {

      // destory user
      Event('closed_account');
      localStorage.clear();
      History.push('/signup');

    });
  }

  return (
    <Fragment>
      <Animate>
        <Card
          title='Edit Your Profile'
          loading={ user.loading } restrictWidth>

          { user?.data &&
            <Form
              buttonText='Save'
              url='/v1/profile'
              method='PATCH'
              data={{
                name: {
                  label: 'Your Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: 'Please enter your email address'
                }
              }}
              callback={ res => {


                // update the user name
                authContext.update({ name: res.data.data.name })

              }}
            />
          }

          <Fragment>
            <br/>
            <Button
              textOnly
              action={ closeAccount }
              text='Close Your Account'
            />
          </Fragment>
        </Card>
      </Animate>
    </Fragment>
  );
}
