/***
*
*   ONBOARDING LAYOUT
*   Simple layout to focus on user onboarding actions
*
**********/

import classNames from 'classnames';
import React, { useEffect } from 'react';
import Style from './scss/onboarding.module.scss';


export function OnboardingLayout(props){
  const css = classNames([
    Style.onboarding ,
    'marginless'
    
  ]);
  useEffect(() => {

    document.body.classList.add('color');
    return () => { document.body.classList.remove('color'); }

  }, [])

  return (
    <main className={ css }>

      { <props.children {...props.data }/> }

    </main>
  );
}
