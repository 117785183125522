/***
*
*   LTI Integration landing page
*   
**********/

import React, { Fragment } from 'react';
import { Animate, Card, useAPI } from 'components/lib';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';

export function Landing(props){
  const {project_id} = useParams();
  const ltiInfo = useAPI(`/lti/${project_id}/info`, 'get');



  return(
    <Fragment>
      <Animate>
        <Card
          title='Welcome to amp-up.io'
          loading={ ltiInfo.loading }>

            
          <ReactJson src={ltiInfo} />
        </Card>
      </Animate>
    </Fragment>
  );
}
