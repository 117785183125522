/***
*
*   API Key Editor
*   Create or edit a new/existing API key
*
**********/

import React, { useState, useContext, useEffect, useCallback, Fragment } from 'react';
import { Animate, Card, Form, Message, TextInput, ViewContext, History, useAPI, AuthContext, CopyToClipboard, Button, Table, Grid } from 'components/lib';
import { useParams } from 'react-router-dom';
import Axios from 'axios';

export function ApiKeyEditor(props){

  // get the scopes
  const authContext = useContext(AuthContext);
  const {api_key_id} = useParams();

  const viewContext = useContext(ViewContext);
  const project_id = authContext.user?.projectId;

  const [newAPIKey, setNewAPIKey] = useState(false);
  const [key,setKey] = useState();
  const [platforms, setPlatforms] = useState([]);
  
  const scopes = useAPI('/v1/project/api/scopes');


  const fetch = useCallback(async () => {
    if (api_key_id) {

      const keyData = await Axios.get(`/v1/project/api/${api_key_id}`);
      const _key =  keyData.data?.data?.[0];
      if (!_key) History.push('/404');
      setKey(_key);

      const platformData = await Axios.get(`/v1/project/api/${api_key_id}/lti`);
      setPlatforms(platformData.data?.data);
    }
    
    
  },[api_key_id]);
  useEffect(()=>{
    if (!key) {fetch()}
  },[fetch,key])



  if (!platforms.loading && platforms.data?.length)
    for (const index in platforms.data) 
      delete platforms.data[index].authConfig;
  function editPlatform (data) {
    viewContext.modal.show({
      title: 'Are you sure?',
      text: `This action will ${data.active? 'deactivate' : 'activate'} the platform ${data.name}.`,
      form: {active: {type: 'hidden', value:!data.active}},
      buttonText: data.active? 'deactivate' : 'activate',
      method: 'PATCH',
      destructive: data.active,
      url: `/v1/project/api/${api_key_id}/lti/${data.id}`
    }, (data) => {
      History.go();
    });    
  }

  function deletePlatform (data) {
    viewContext.modal.show({
      title: 'Are you sure?',
      text: `This action will permanently delete the platform ${data.name}.`,
      form: {},
      buttonText: 'Delete',
      method: 'DELETE',
      destructive: true,
      url: `/v1/project/api/${api_key_id}/lti/${data.id}`
    }, (data) => {
      History.go();
    });  
  }  
  return (
    <Animate>

      { newAPIKey ? 
        <Message 
          closable
          title='Your New API Key'
          type='warning'
          buttonText='Back to API Keys'
          buttonLink={`/project/${project_id}/api`}
          text='Your API key is below. Please store this somewhere safe.'>

            <Grid cols='2'>
              <TextInput value={ newAPIKey } />
              <CopyToClipboard text={newAPIKey}
                onCopy={()=>{viewContext.notification.show('Copied!', 'success',true)}}>
                <Button small textOnly icon='copy' style={{backgroundColor: 'transparent !important'}}/>
              </CopyToClipboard>
            </Grid>
        </Message>  : 
        <div>
        <Card title={ `${key ? 'Edit': 'Create'} API Key` } loading={ scopes.loading }> 
          {key ? 
            <Fragment>

              <div>
                {key.key} 
                &nbsp;
                &nbsp;
                <CopyToClipboard text={key.key || ''}
                  onCopy={()=>{viewContext.notification.show('Copied!', 'success',true)}}>
                  <Button small textOnly icon='copy' />
                </CopyToClipboard>
                &nbsp;
                <br/><br/>
              </div>
              <Form 
                data={{
                  name: {
                    label: 'Name',
                    type: 'text',
                    required: true,
                    value: key.name, 
                    errorMessage: 'Please provide a descriptive name for your key.' 
                  },
                  scope: {
                    type: 'checkbox',
                    label: 'Scope',
                    required: true,
                    min: 1,
                    default: key.scope,
                    options: scopes.data,
                    errorMessage: 'Please select at least one scope' 
                  }
                }}
                url={ key ? `/v1/project/api/${key?.id}` : '/v1/project/api' }
                method={ key ? 'PATCH' : 'POST' }
                buttonText={ key ? 'Save Changes' : 'Create New API Key' }
                callback={ res => {
                  
                  !key && setNewAPIKey(res?.data?.data?.full_key) 
                
                }}
              />
            </Fragment>
          : 
            <Form 
              data={{
                name: {
                  label: 'Name',
                  type: 'text',
                  required: true,
                  errorMessage: 'Please provide a descriptive name for your key.' 
                },
                scope: {
                  type: 'checkbox',
                  label: 'Scope',
                  required: true,
                  min: 1,
                  default: scopes.data,
                  options: scopes.data,
                  errorMessage: 'Please select at least one scope' 
                }
              }}
              url={ key ? `/v1/project/api/${key?.id}` : '/v1/project/api' }
              method={ key ? 'PATCH' : 'POST' }
              buttonText={ key ? 'Save Changes' : 'Create New API Key' }
              callback={ res => {
                
                !key && setNewAPIKey(res?.data?.data?.full_key) 
              
              }}
            />
          }
        </Card>
        <Table 
          title={`LTI Platforms registered with ${key?.name}`}
          data={platforms.data} 
          show={['url', 'clientId','name', 'active']}
          actions={{ 
            delete: deletePlatform,
            edit: editPlatform,
          }}          />
      
        </div>
     
      }
    </Animate>
  )
}