/***
*
*   USERS
*   Enables an admin to manage the users in the project
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext, ViewContext, Table, Animate, useAPI, useRoles, Event } from 'components/lib';

export function Users(props){

  const context = useContext(ViewContext);
  const auth = useContext(AuthContext);

  const projectId = auth.user?.projectId;

  const roleData = useRoles();
  const userData = useAPI(`/v1/project/users`);
  const [users, setUsers] = useState([]);

  function invite(){ 

    context.modal.show({
      title: 'Add User',
      form: {
        email: {
          label: 'Email',
          type: 'text',
          required: true,
        },
        permission: {
          type: 'hidden',
          value: 'user'
        },
        project_id: {
          type: 'hidden',
          value: projectId
        },
        role: {
          label: 'Role',
          type: 'select',
          default: 'editor',
          options: roleData?.options
        }
      },
      buttonText: 'Add Member',
      text: 'To add more than one member, separate the emails with a comma',
      url: '/v1/invites',
      method: 'POST',

      }, (form, res) => {

        // add the invited user to the
        if (res.invited.length || res.added.length){

          const state = [...users];

          res.invited?.forEach(invite => {
            if (!state.find(x => x.id === invite.id)){
              state.push({

                id: invite.id,
                name: '',
                email: invite.email,
                date_created: invite.date_sent,
                role: invite.role,
                status: 'Invited',
                actions: {

                  invite: resendInvite,
                  delete: deleteInvite

                },
              });
            }
          });
          res.added?.forEach(user => {
            if (!state.find(x => x.id === user.id)){
              state.push({

                id: user.id,
                name: user.name,
                email: user.email,
                date_created: user.date_created,
                role: user.role,
                status: 'Registered',
                actions: {

                  edit: editUser, 
                  delete: deleteUser, 
                  email: true 
                },
              });
            }
          });

          Event('invited_user');
          setUsers(state);

        }
    });
  }

  function editUser(data, callback){

    context.modal.show({
      title: 'Update User',
      form: {
        user_id: {
          type: 'hidden',
          value: data.id
        },
        role: {
          label: 'Role',
          type: 'select',
          default: data.role,
          options: roleData?.options 
        }
      },
      buttonText: 'Save',
      url: `/v1/project/users/${data.id}`,
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteUser(data, callback){
    if (data.is_admin) {
      context.notification.show("Administrators cannot be removed from a project", 'error', true);
      return;
    }
    context.modal.show({
      title: 'Delete User',
      form: {
        user_id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${data.name}?`,
      url: `/v1/project/users/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();

    });
  }

  function deleteInvite(data, callback){
    
    context.modal.show({
      title: 'Delete Invite',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Invite',
      text: `Are you sure you want to delete the invite for ${data.email}?`,
      url: `/v1/invites/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(`${data.email}'s invite was deleted`, 'success', true);
      callback();

    });
  }

  async function resendInvite(data){
    try {

      await axios({ 
        
        url: '/v1/invites',
        method: 'post',
        data: { email: data.email }
      
      });
      
      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true);

    }
    catch(err){

      context.handleError(err);

    }
  }
  
  useEffect(() => {

    // format the user list
    let list = [];

    if (userData?.data?.users?.length){
      list = userData.data.users.map(x => {
        return {

          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          role: x.role,
          status: 'Registered',
          is_admin: x.is_admin

        }
      })
    }

    if (userData?.data?.invites?.length){
      userData.data.invites.forEach(x => {
        list.push({

          id: x.id,
          name: '',
          email: x.email,
          date_created: x.date_sent,
          role: x.role,
          status: 'Invited',
          is_admin: false

        });
      });
    }

    setUsers(list);

  }, [userData]);

  // attach the per row actions for invites
  if (users.length){
    users.forEach(u => {
      if (u.status === 'Invited'){
        u.actions = {

          invite: resendInvite,
          delete: deleteInvite

        }
      } else {
        u.actions={ 
          edit: editUser, 
          delete: deleteUser, 
          email: true 
        }
      }
    })
  }

  return (
    <Fragment>

      <Animate>

        <Table
          search
          title='Manage Project Members'
          addNew={{
            label:'Add Member',
            action: invite
          }}
          data={ users }
          loading={ userData?.loading }
          show={['email', 'name', 'date_created', 'last_login', 'role', 'status']}
          badge={{ col: 'status', color: 'blue', condition: [

            { value: 'registered', color: 'green' },
            { value: 'invited', color: 'blue' }

          ]}}
        />

      </Animate>
    </Fragment>
  );
}
