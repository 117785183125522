import { NotFound } from 'views/error/404';
import { Forbidden } from 'views/error/403';
import { Unauthorized } from 'views/error/401';
import { Error } from 'views/error/error';

const Routes = [
  {
    path: '/error/404',
    view: NotFound,
    layout: 'website',
    title: '404'
  },
  {
    path: '/error/403',
    view: Forbidden,
    layout: 'website',
    title: '403'
  },
  {
    path: '/error/401',
    view: Unauthorized,
    layout: 'website',
    title: '401'
  },
  {
    path: '/error',
    view: Error,
    layout: 'chromeless',
    title: 'Error'
  }
]

export default Routes;
