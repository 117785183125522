/***
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import { ProjectNav, AccountNav, GuestNav} from 'components/lib';
import './navbar.scss'

export function Navbar(props){
      

  // style
  const cssClass = ClassNames([
    props.className,
    'navbar'
  ]);
             
  const subNavs = [
    {component: ProjectNav, route: 'project'},
    {component: AccountNav, route: 'account'},
  ];

  const subNavPath = window.location.pathname.split('/')[1];

  let SubNav = subNavs.find(x => x.route === subNavPath)?.component;

  if (!SubNav && props.layout !== 'onboarding') {
    SubNav = GuestNav; 
  }

  return(
    <nav className={ cssClass }>
      {/*onMouseEnter={ e => setExpanded(true)}
      onMouseLeave={ e => setExpanded(false)}>*/}
      
      <section className='nav-links'>
        <SubNav /> 
      </section>
    </nav>
  );
}
