/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import { Logo, Navbar, ProjectHeader, AccountHeader } from 'components/lib';
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './scss/layout.scss';

export function AppLayout(props){
  
  useEffect(() => {
    const defaultState = {
      leftOpen: true,
      clickOpen: true
    };
    let sidebarState;
    try{
      sidebarState = JSON.parse(localStorage.getItem('appSidebar'));
      if (!sidebarState) sidebarState=defaultState; 
    } catch (err) {
      sidebarState = defaultState 
    } 
    

    setSidebar(sidebarState); 
    localStorage.setItem('appSidebar',JSON.stringify(sidebarState))
  },[])

  // state
  const [sidebar, setSidebar ] = useState({});
 
  const toggleSidebar = (event) => {
    const sidebarState = {leftOpen: !sidebar.clickOpen, clickOpen: !sidebar.clickOpen  }; 
    setSidebar(sidebarState);
    localStorage.setItem('appSidebar',JSON.stringify(sidebarState))
  }

  const openSidebar = (event) => {
    if (sidebar && !sidebar.clickOpen) {
      setSidebar(prev => {return {...prev, leftOpen: true }});
    }
    event.cancelBubble = true;
  }
  const closeSidebar = (event) => {
    if (sidebar && !sidebar.clickOpen) {
      setSidebar(prev => {return {...prev, leftOpen: false }});
    } 
    event.cancelBubble = true;
  }

  let leftOpen = sidebar?.leftOpen ? 'open' : 'closed';
  
   
  return (
    <Fragment>
      <div id='layout'>

        <div id='left' 
            className={leftOpen}
            onMouseEnter={openSidebar} 
            onMouseLeave={closeSidebar}>
          <div className='icon'
            onClick={toggleSidebar} 
          >
            <Logo className='logo' mark />
          </div>
          <div className={`sidebar ${leftOpen}`} >
            <div className='header'>
              <span className='title ampup'>
                <Link to='/'>amp-up.io</Link>
              </span>
            </div>
            <div>
              <Navbar className={leftOpen} />
            </div>
          </div>
        </div>

        <div id='main'>
          <div className='header'>
            <span className={`
                    title
                    menu
                    ${'left-' + leftOpen}
                `}>
              <ProjectHeader />
            </span>
            <span className='spacer' />
            <span className='menu'>
              <AccountHeader />
            </span>
          </div>
          <div id='content'>
            {<props.children {...props.data} />}
          </div>
        </div>
      </div>            
  
    </Fragment>
  );
}
