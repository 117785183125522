import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
]

export default Routes;
