/***
*
*   usePermissions hook
*   fetch, format and return the user permissions
*
**********/

import { useState, useEffect } from 'react';
import { useAPI } from 'components/lib';

export function usePermissions(){

  const [state, setState] = useState({ permissions: null, options: null,  loading: false });
  const permissions = useAPI('/v1/platform/permissions');

  useEffect(() => {

    setState({ loading: true });

    // format permissions
    let perms = [];
    if (permissions.data){

      Object.keys(permissions?.data).map(perm => {

        perms.push({ value: perm, label: perm });
        return perms;

      });
    }
    setState({ 
      permissions: permissions.data,
      options: perms,
      loading: false 
    });
        
  }, [permissions]);

  return state;

}
