/***
*
*   useAPI hook
*   Interact with API calls – handle errors, return loading state and data
*
*   PROPS
*   url: endpoint url
*   method: get, post, put etc.. (default: get)
*
**********/

import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Axios from 'axios';
import { ViewContext } from 'components/lib';

export function useAPI(url, method, data){
  const mounted  = useRef(false);
  const context = useRef(useContext(ViewContext));
  const [state, setState] = useState({ data: null, loading: true })

  const searchParams = new URLSearchParams(window.location.search)
  const ltik = searchParams.get('ltik')

  const fetch = useCallback(async () => {
    const callData = {
      url: url,
      method: method || 'get'
    }
  
    if (data) {
      callData.body = data;
    }
  
    if (ltik) {
      callData.headers = {Authorization: 'Bearer ' + ltik };
      callData.credentials = 'include'; 
    }

    try {

      if (!callData.url){

        setState({ data: null, loading: false });
        return false;

      }

      const res = await Axios(callData)

      setState({ data: res.data.data ? res.data.data : res.data, loading: false });

      if (res.data.message) 
        context?.current?.notification.show(res.data.message, 'success', {autoclose: true});
    } catch (err){
      if (mounted.current) 
        if (context?.current) 
          context?.current?.handleError(err);
        else 
          throw err;
    }
  }, [context, data, ltik, method, url]);

  useEffect( () => {
    mounted.current = true;
    fetch();
    
    return () => mounted.current = false;
  }, [fetch]);

  return state

}
