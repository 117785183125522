/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message, Row } from 'components/lib';

export function OnboardingView(props){

  const context = useContext(AuthContext);
  const views = [{

    name: 'Getting Started',
    description: `Welcome, ${context.user.name}!`,
    component: <Welcome/>

  }]

  if (context.permission.admin){
    views.push({

      name: 'Invite your team', 
      description: 'amp-up.io is more fun when you invite your team.',
      component: <InviteUsers/>,
      
    });
  }

  if (context.user.duplicate_user){
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser/>

    })
  }

  return <Onboarding save onFinish='/project' views={ views }/>

}

function DuplicateUser(){

  return (
    <Message
      type='warning'
      title={ `You already have an account` }
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />    
  )
}

function Welcome(){

  return (
    <Fragment>

      <Row>
        Welcome to amp-up.io! We are excited to show you around. While
        you can set up a new project from your console at any time, 
        we think the best way to learn the features of teh platform
        is by seeing it in action. So we have set up a demo project 
        for you get you started. You can delete this demo project at
        any time, and it does not count towards your license. 
      </Row>
        
      <Row>
        We hope you enjoy amp-up.io!   
      </Row>


    </Fragment>
  )
}

function InviteUsers(props){

  return (
    <Fragment>
      <Row>
        Invite one or more colleagues to collaborate on amp-up.io and
        take advantage of our team focused workflows that will help you
        author, review, publish, deliver and score your assesments in no time. 
      </Row>
      <Form 
        data={{
          email: {
            label: 'Email',
            type: 'email',
            required: true,
          }
        }}
        buttonText='Send Invite'
        url='/v1/invites'
        method='POST'
      />
    </Fragment>
    
  )
}