/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import React, {Fragment, useContext, useState, useEffect} from 'react';
import Style from './header.module.scss';
import { HoverNav, Button, ViewContext, AuthContext, History} from 'components/lib'
import { NavLink } from 'react-router-dom';



export function AccountHeader(props){
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  
  const [user, setUser] = useState();
  
  useEffect(() => {
    if (authContext) setUser(authContext.user);
  },[authContext]);

  if (user?.token) {

    return (
      <HoverNav 
        //dark
        icon='user' 
        label={ authContext.user?.name || 'user' } 
        className={ [ Style.user ] }
        align='right' >
          <Button 
                key='Settings'
                text='Settings'
                icon='settings'
                alignIcon='right'
                action={() => {History.push('/account')}} 
              /> 
          <Button 
                key='Logout'
                text='Logout'
                icon='log-out'
                alignIcon='right'
                action={() => {
                  viewContext.setLoading(true);
                  authContext.signout('/');
                  viewContext.setLoading(false);
      
                }}
              /> 
      </HoverNav>

  );
  } else return (
    <Fragment>
          
      <NavLink exact to='/signin' className={Style.navItem}>Sign In</NavLink>
      &nbsp;&nbsp;
      <NavLink exact to='/signup' className={Style.navItem}>Sign Up</NavLink>
    </Fragment>
  );
}
