/***
*
*   USERS
*   Enables an admin to manage the users in the project
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { ViewContext, AuthContext, Table, Animate, useAPI, History} from 'components/lib';

export function Tests(props){
 
  const auth = useContext(AuthContext);

  const projectId = auth.user?.projectId;
  const context = useContext(ViewContext);
  const testData = useAPI('/v1/qti/tests');
  const [tests, setTests] = useState([]);

  function addTest(){

    context.modal.show({
      title: 'Add Test',
      form: {
        title: {
          label: 'Title',
          type: 'text',
          required: true,
        },
        description: {
          label: 'Description',
          type: 'textarea'
        }
      },
      buttonText: 'Add Test',
      url: `/v1/project/${projectId}/tests`,
      method: 'POST',

      }, (form, res) => {
        const state = [ ...tests ];
        state.push(res);        
          setTests(state);
      }
    );
  }
/*
  function editTest(data, callback){

    context.modal.show({
      title: 'Update Test',
      form: {
        title: {
          label: 'Title',
          type: 'text',
          value: data.title,
          required: true,
        },
        description: {
          label: 'Description',
          value: data.description,
          type: 'textarea'
        }
      },
      buttonText: 'Save',
      url: `/v1/qti/tests/${data.id}`,
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.title + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteTest(data, callback){
    context.modal.show({
      title: 'Delete Test',
      form: {
        user_id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Test',
      text: `Are you sure you want to delete ${data.title}? Items in this test will NOT be deleted.`,
      url: `/v1/qti/tests/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(data.title + ' was deleted', 'success', true);
      callback();

    });
  }
*/

  useEffect(() => {

    // format the user list
    if (testData?.data?.length) {

      setTests(testData.data);
    }
  }, [testData]);


  return (
    <Fragment>

      <Animate>


        <Table
          search
          title='Tests'
          addNew={{
            label:'Add Test',
            action: addTest
          }}
          className='restrict-width'
          data={ tests }
          loading={ testData.loading }
          show={['title', 'description', 'count']}
          rowAction={{
            action: (col) => {
              History.push(`/project/${projectId}/tests/${col}`)
            },
            col: 'id'
          }}
          /*
          actions={{
            ...roleData.roles && roleData.roles[auth.role]?.write && {edit:  editTest},
            ...roleData.roles && roleData.roles[auth.role]?.write && {delete: deleteTest}
          }}
          */
        />

      </Animate>
    </Fragment>
  );
}
