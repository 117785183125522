/***
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/

import React, {useContext} from 'react';
import { AuthContext, useProject, HoverNav, Button} from 'components/lib';
import Style from './header.module.scss'

export function ProjectHeader(props){
  const authContext = useContext(AuthContext);
  const projData = useProject();
      
  if (!authContext.user)
    return null; 

  // state
  const project = projData.project;

  let projButtons;

  if (projData.projects) {

    projButtons = projData.projects?.map(proj => {
      return <Button 
              key={proj.id}
              text={proj.name}
              action={() => {authContext.setActiveProject(proj.id);}}
            /> 
    });      
  
  }
  return(
        <HoverNav
          icon= 'chevron-down'
          label= {project.name || 'All Projects'}
          className={ [Style.project ]}
          align='center'>
            <Button 
              key='viewAll'
              text='All Projects'
              url='/project'
            />
          { projButtons }
        </HoverNav>
  );
}
