/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React from 'react';
import LogoImage from './images/logo.svg';
import LogoMark from './images/logo-mark.svg';

export function Logo(props){

  return(
    <img src={ props.mark ? LogoMark : LogoImage } className={props.className} alt='Logo' />
    
  )
}
