/***
*
*   CHROMELESS LAYOUT
*   Specialized layout for use in embedded pages (eg: LTI launches)
*
**********/

import React, { Fragment } from 'react';

import './scss/layout.scss';

export function ChromelessLayout(props){

   
  return (
    <Fragment>
      <div id='layout'>
      <div id='content' className='marginless'>
        { <props.children {...props.data }/> }
      </div>
      </div>
    </Fragment>
  );
}
