// history
export const History = require('history').createBrowserHistory();

// view
export { View } from './view/view';
export { Modal } from './view/modal/modal';
export { Notification } from './view/notification/notification';
export { Navbar } from './view/navbar/navbar';
export { Animate } from './animate/animate';

// context
export { ViewContext } from './view/view';
export { AuthContext, AuthProvider } from '../app/auth';

// layout
export { AppLayout } from './view/layout/app';
export { WebsiteLayout } from './view/layout/website';
export { OnboardingLayout } from './view/layout/onboarding';
export { ChromelessLayout } from './view/layout/chromeless';
export { LTILayout } from './view/layout/lti';

export { Grid } from './grid/grid';
export { Row } from './row/row';
export { Content } from './row/content';


// nav
export { AccountHeader } from './view/header/account';
export { ProjectHeader } from './view/header/project';
export { SubNav } from './view/navbar/sub/sub';
export { AccountNav } from './view/navbar/sub/account';
export { ProjectNav } from './view/navbar/sub/project';
export { GuestNav } from './view/navbar/sub/guest';
export { Breadcrumbs } from './nav/breadcrumbs/breadcrumbs';
export { HoverNav } from './nav/hover/hover';


// form
export { Form } from './form/form';
export { PaymentForm } from './form/form';
export { Switch } from './form/switch/switch';
export { Checkbox } from './form/checkbox/checkbox';
export { Radio } from './form/radio/radio';
export { Error } from './form/error/error';
export { Fieldset } from './form/fieldset/fieldset';
export { CardInput } from './form/input/card';
export { EmailInput } from './form/input/email';
export { HiddenInput } from './form/input/hidden';
export { DateInput } from './form/input/date';
export { NumberInput } from './form/input/number';
export { PasswordInput } from './form/input/password';
export { PhoneInput } from './form/input/phone';
export { TextInput } from './form/input/text';
export { FileInput } from './form/file/file';
export { URLInput } from './form/input/url';
export { Label } from './form/label/label';
export { Legend } from './form/fieldset/legend';
export { Select } from './form/select/select';
export { FormHeader } from './form/header';

// list
export { List } from './list/list';
export { CheckList } from './list/checklist/checklist';

// message
export { Message } from './message/message';
export { BlankSlateMessage } from './message/blankslate/blankslate';

// homepage
export { PricePlans } from './pricing/plans';
export { Footer } from './view/footer/footer';

// hooks
export { useAPI } from './hooks/api';
export { usePlans } from './hooks/plans';
export { usePermissions } from './hooks/permissions';
export { useRoles } from './hooks/roles';
export { useProject } from './hooks/project';
export { useAccount } from './hooks/account';
export { useLTI } from './hooks/lti';


// the rest
export { Article } from './article/article';
export { Button } from './button/button';
export { Link } from './link/link';
export { SocialSignin } from './social/signin';
export { Loader } from './loader/loader';
export { TabView} from './tabview/tabview';
export { Card } from './card/card';
export { Logo } from './logo/logo';
export { Image } from './image/image';
export { Table } from './table/table';
export { Badge } from './badge/badge';
export { Icon } from './icon/icon'
export { Search } from './search/search';
export { Onboarding } from './onboarding/onboarding';
export { CreditCard } from './card/credit';
export { Paginate } from './paginate/paginate';
export { Event } from './event';
export { CopyToClipboard } from './copyToClipboard/copyToClipboard';


//test Player
export { SessionControlSettings } from './qti/sessionControlSettings';
