/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import Style from './sub.module.scss';

export function AccountNav(props){

  const context = useContext(AuthContext);

  return(
    <nav className={ Style.subnav } >

      <NavLink exact to='/account/profile' activeClassName='active'>Profile</NavLink>
      <NavLink exact to='/account/profile/password' activeClassName='active'>Password</NavLink>
      <NavLink exact to='/account/profile/2fa' activeClassName='active'>2FA</NavLink>

      { context.permission?.owner &&
        <NavLink exact to='/account/billing' activeClassName='active'>Billing</NavLink> }

      { context.permission?.admin &&
        <NavLink exact to='/account/users' activeClassName='active'>Users</NavLink> }

      { context.permission?.admin &&
        <NavLink exact to='/account/projects' activeClassName='active'>Projects</NavLink> }

    </nav>
  );
}
