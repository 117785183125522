import React, { Fragment, useContext, } from 'react';
import { AuthContext, ViewContext, Table, useProject, History } from 'components/lib';

export function Projects(props){
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const projectContext = useProject();

  
  function addProject () {
      viewContext.modal.show({
        title: 'Create Project',
        form: {
          name: {
            type: 'text',
            label: 'Project Name',
            required: true,
          },
          description: {
            type: 'textarea',
            label: 'Description',
            required: false,
          }
        },
        buttonText: 'Save Project',
        url: '/v1/account/projects',
        method: 'POST'
      }, (data) => {
        History.go();
      })
  }
  
  function editProject (data) {
    viewContext.modal.show({
      title: 'Edit Project',
      form: {
        id: {
          type: 'hidden',
          value: data.id
            
        },
        name: {
          value: data.name,
          type: 'text',
          label: 'Project Name',
          required: true,
        },
        description: {
          value: data.description,
          type: 'textarea',
          label: 'Description',
          required: false,
        }
      },
      buttonText: 'Edit Project',
      url: `v1/account/projects/${data.id}`,
      method: 'PATCH'
    }, (data) => {
      History.go();
    })
  }

  function deleteProject (data) {
    viewContext.modal.show({
      title: 'Are you sure?',
      text: `Deleting a project is permanent and cannot be undone. Are you sure you want to delete ${data.name}?`,
      form: {},
      buttonText: 'Delete',
      method: 'DELETE',
      url: `/v1/account/projects/${data.id}`
    }, () => {
      History.go();
    });

  }

  
  return(
    <Fragment>


        <Table 
          title="Projects"
          loading={projectContext.loading}
          addNew= {{
            label: 'Add Project',
            action: addProject
          }}
          data={projectContext.projects} 
          search={true}
          show={['name', 'description']}

          rowAction={{ 
            action: (col) => {authContext.setActiveProject(col);},
            col:'id'
          }}
          actions={{ 
      
            edit: editProject, 
            delete: deleteProject
          }}
        >
        </Table>
      
    </Fragment>

  );
}
