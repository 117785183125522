/***
*
*   useAccount hook
*   grab Account information for the current user
*
**********/

import { useContext} from 'react';
import { AuthContext } from 'components/lib';

export function useAccount(){
  const context = useContext(AuthContext);
  const user = context.user;

  const adminAccounts = user.accounts?.filter(x => x.permission === 'admin' ||
                                                    x.permission ==='owner');

  const payload = {   
    accounts: user.accounts , 
    adminAccounts: adminAccounts
  }
 
  return payload;

}

