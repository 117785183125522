/***
*
*   LTI Platform Registration
*   
**********/

import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import { Animate, ViewContext,  Form, Card, Row, useAPI } from 'components/lib';

export function Registration(props){
  const {project_id} = useParams();
  const proj = useAPI(`/v1/lti/projects/${project_id}`);

  // context
  const viewContext = useContext(ViewContext);
  
  // invite
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const iss = searchParams.iss;
  const clientId = searchParams.client_id;

  // state
  const form = {
    project_id: project_id,    
    iss: {
      type: 'hidden',
      value: iss,
    },
    client_id: {
      type: 'hidden',
      value: clientId
    },
    organization: {
      label: 'Course Name (or Organization name for site wide deployments)',
      type: 'text',
      errorMessage: 'Enter your course name'
    },
    api_key: {
      label: 'License Key',
      type: 'text',
      errorMessage: 'Enter your amp-up.io License Key'
    },
    platform_type: {
      label: 'LMS Type',
      type: 'select',
      options: [
        {label:'Moodle', value:'moodle'},
        {label:'Canvas Cloud', value:'canvas-cloud'},
        {label:'Canvas Standalone', value:'canvas-standalone'},
      ],
    },
  };

  
  useEffect(() => {


    if (searchParams.error){
  
      viewContext.notification.show(decodeURIComponent(searchParams.error), 'error');
  
    }
  }, [searchParams.error, viewContext.notification]);


  return(
    <Animate type='pop'>
      <Row title={`${proj.data?.name} LTI Gateway by amp-up.io`}>


          <Card center>
            <div>
            To continue please enter your LTI Key. If you do not have a key, you can obtained one from the content provider.<br/> 
            </div>
            <Form
              data={ form }
              method='POST'
              url={`/v1/lti/registration`}
              buttonText={ 'Register' }
              callback={ res => {
              
                viewContext.notification.show('Posted'); 
              
              }}
            />

          </Card>
          <Card>
            ISS: {iss}<br/>
            Client_id: {clientId}
          </Card>
      </Row>
    </Animate>
  );
}
