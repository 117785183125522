import React, { Fragment, useContext } from 'react';
import { ViewContext, Table, AuthContext, History, useProject, Animate } from 'components/lib';

export function Projects(props){
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const projectContext = useProject();

  function addProject () {
    viewContext.modal.show({
      title: 'Create Project',
      form: {
        name: {
          type: 'text',
          label: 'Project Name',
          required: true,
        },
        ...authContext.user.accounts?.length > 1 && {account_id: {
          label: 'Account',
          type: 'select',
          options: authContext.user.accounts.map(account => {return {value: account.id, label: account.name}})
        }},
        description: {
          type: 'textarea',
          label: 'Description',
          required: false,
        }
      },
      buttonText: 'Save Project',
      url: 'v1/account/projects',
      method: 'POST'
    }, (data) => {
      History.go();
    })
  }

  function editProject (data) {
    viewContext.modal.show({
      title: 'Edit Project',
      form: {
        id: {
          type: 'hidden',
          value: data.id
            
        },
        name: {
          value: data.name,
          type: 'text',
          label: 'Project Name',
          required: true,
        },
        description: {
          value: data.description,
          type: 'textarea',
          label: 'Description',
          required: false,
        }
      },
      buttonText: 'Edit Project',
      url: `v1/project`,
      method: 'PATCH'
    }, (data) => {
      History.go();
    })
  }

  function deleteProject (data) {
    if (data.is_admin) 
      viewContext.modal.show({
        title: 'Are you sure?',
        text: `This action will permanently delete the Project ${data.name}.`,
        form: {},
        buttonText: 'Delete',
        method: 'DELETE',
        destructive: true,
        url: `v1/account/projects/${data.id}`
      }, (data) => {
        History.go();
      });    
    else
      viewContext.modal.show({
        title: 'Are you sure?',
        text: `You will no longer have access to the Project ${data.name}. An administrator will have to restore your access.`,
        form: {
          id: {
            type: 'hidden',
            value: data.id
              
          }
        },
        buttonText: 'Delete',
        method: 'DELETE',
        destructive: true,
        url: `v1/profile/projects`
      }, (data) => {
        History.go();
      });

  }


  return(
    <Fragment>
      <Animate>

      <Table 
        title='QTI Projects'
        addNew={{
          label:'Add Project',
          action: addProject
        }}
        loading={projectContext.loading}
        data={projectContext.projects} 
        search={true}
        show={['name', 'description', 'account', 'is_admin']}
        badge={{ col: 'is_admin', color: 'blue', condition: [

          { value: true, color: 'green' },
          { value: false, color: 'red' }
        
        ]}}
        rowAction={{ 
          action: (col) => {authContext.setActiveProject(col);},
          col:'id'
        }}
        actions={{
          custom: [{
            condition: {col: 'is_admin', value:true},
            action: editProject,
            icon: 'edit'
          }],
          delete: deleteProject,
        }}
      >
      </Table>
      </Animate>
        
    </Fragment>

  );
}
