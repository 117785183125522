/***
*
*   Test Player
*   
**********/

import React, { Fragment,  useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import { Button, Animate, useAPI, History, CopyToClipboard, SessionControlSettings, ViewContext, Card, TabView, Select, Label} from 'components/lib';
import Axios from 'axios';

export function Test(props){
  const viewContext = useContext(ViewContext);
  const {test_id} = useParams();

  const testData = useAPI(`/v1/qti/tests/${test_id}`);
  const [test, setTest] = useState([]);

  const apiKeyData = useAPI('/v1/project/api')
  const [apiKeys, setApiKeys] = useState([]);
  const [apiKeyId, setApiKeyId] = useState();

  const [player, setPlayer] = useState();

  
  useEffect(() => {

    // format the user list
    if (testData.data) 
      setTest(testData.data);
    
    if (apiKeyData.data) {
      const activeApiKeys = [];
      apiKeyData.data.forEach((key)=>{if (key.active) activeApiKeys.push(key)})
      setApiKeys(activeApiKeys);
      
    }


  }, [testData, apiKeyData]);

  async function submit(data) {
    let useKeyId = apiKeyId;

    if (data.apiKeyId) {
      setApiKeyId(data.apiKeyId);
      useKeyId = data.apiKeyId;
    } 

    if(!useKeyId) {
      return;
    }

    const scdata = {
      apiKeyId: useKeyId,
      ...data.pnp && {pnp: data.pnp},
      ...data.sessionControl && {sessionControl: data.sessionControl}
    };
    const playerData = await Axios.post(`/v1/qti/tests/${test_id}/player`, scdata);
    setPlayer(playerData.data.data);
  }
  return (
    <Fragment>

      <Animate>
        <TabView name='projectPlayer' labels={['About', 'Create Link', 'Preview']}>

          <Card title={test.title}>
            <h3>{test.count} Questions</h3>
            <p dangerouslySetInnerHTML={{__html: `${test.description}`}}></p>
          </Card>
          <Card title="Create Test Player Link">
            {
              !apiKeys.length ?
              <Fragment>
                <p>In order to get started, you need to create an active API Key first.</p>
                <Button text="Create Key" action={()=>History.push('../api')}/>
              </Fragment>
              : 
              <Fragment>
              <div style={{textAlign:'center'}} >
              <CopyToClipboard text={player?.url || ''}
                onCopy={()=>{viewContext.notification.show('Copied!', 'success',true)}}>
                <Button small outline icon='copy' text='Copy Link' disabled={!player?.url} />
              </CopyToClipboard>
              </div>


              <Label
                text='API Key'
                required= {true}
                for='api_key_id'/>
            
              <Select
                name='api_key_id'
                id='api_key_id'
                options= {apiKeys.map((key)=>{if (!key.active) return null; return {value:key.id,label:key.name}})}
                onChange={(name,value)=> {
                  if (value==='unselected') {
                    setPlayer(null);
                    setApiKeyId(null);
                  } else {
                    submit({apiKeyId: value});
                  }
                }}/>
              <SessionControlSettings
                small
                outline
                icon='settings'
                text='Launch Settings'
                disabled={ !apiKeyId }
                callback={submit}/>
              &nbsp;
              &nbsp;
            </Fragment>
            }
          </Card>
          <Card>
            { player?.url ? 

              <iframe id='player' title='amp-up.io Player' src={player.url} style={{width: '100%',height: 'calc(100vh - 13em)'}}/>
            :
              <div style={{width: '100%',height: 'calc(100vh - 13em)'}}><p>Select an API Key in the Settings Tabs</p>  </div>
            

            }
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
