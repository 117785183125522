import { Projects } from 'views/project/projects';
import { Project } from 'views/project/project';
import { Users } from 'views/project/users';
import { Tests } from 'views/project/qti/tests';
import { Test } from 'views/project/qti/test';
import { Api } from 'views/project/api/index';
import { ApiKeyEditor } from 'views/project/api/key';

const Routes = [
  {
    path: '/project',
    view: Projects,
    layout: 'app',
    permission: 'user',
    title: 'Projects'
  },
  {
    path: '/project/:project_id',
    view: Project,
    layout: 'app',
    permission: 'read',
    title: 'Project'
  },
  {
    path: '/project/:project_id/users',
    view: Users,
    layout: 'app',
    permission: 'manage',
    title: 'Project Team'
  },
  {
    path: '/project/:project_id/tests',
    view: Tests,
    layout: 'app',
    permission: 'read',
    title: 'Tests'
  },
  {
    path: '/project/:project_id/tests/:test_id',
    view: Test,
    layout: 'app',
    permission: 'read',
    title: 'Test'
  },
  {
    path: '/project/:project_id/api',
    view: Api,
    permission: 'developer', 
    title: 'API License Keys'
  },
  {
    path: '/project/:project_id/api/create',
    view: ApiKeyEditor,
    permission: 'developer', 
    title: 'Create API License Key'
  }, 
  {
    path: '/project/:project_id/api/:api_key_id',
    view: ApiKeyEditor,
    permission: 'developer',
    title: 'Manage API License Key'
  },

]

export default Routes;
