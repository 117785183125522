/***
*
*   LTI Integration landing page
*   
**********/

import React, { Fragment, useEffect, useState,  } from 'react';
import { Animate, History, useAPI, useLTI, Button, Table} from 'components/lib';
import { useParams } from 'react-router-dom';

export function Resources(props){
  const ltiInfo =  useLTI(true);

  const {type} = useParams();
  const {project_id} = useParams();
  const [dataset, setDataset] = useState([])

  const searchParams = new URLSearchParams(window.location.search);
  const ltik = searchParams.get('ltik');  
  
  const resources = useAPI(`/lti/${project_id}/resources/${type}`, 'get');

  useEffect(() => {
    if (ltiInfo?.error) {
      ltiInfo.handleError();
    } else if(resources.data?.length > 0 && !resources.loading) {
      setDataset(resources.data);
    }
  },[ltiInfo, resources])

  const columns = ['title','description']
  

  function rowSelected(id) {
    History.push(`../settings?ltik=${ltik}&type=${type}&id=${id}`);
    
  }
  
  return(
    <Fragment>
      <Animate>
        <Button icon='arrow-left' small text='Back to Content Type' action={() => History.goBack() } />
        <Table
          title={`Available ${type}`}
          loading={ resources.loading }
          search
          className='restrict-width'
          data={ dataset }
          show={columns}
          rowAction={{
            action: (col) => {
              rowSelected(col);
            },
            col: 'id'
          }}
        />
      </Animate>
    </Fragment>
  );
}
