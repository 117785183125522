/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import qs from 'qs';
import { Animate, AuthContext, ViewContext, Button, Form, Card, Link, Row, Message, SocialSignin, History } from 'components/lib';

export function Signin(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  
  // invite
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const id = searchParams.id;
  const email = searchParams.email;

  // state
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({

    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    }
  });

  if (id && email) {
    form.email.value = email;
    form.invite_id = {
      type : 'hidden',
      value : id
    };
  }
  
  useEffect(() => {


    if (searchParams.error){
  
      viewContext.notification.show(decodeURIComponent(searchParams.error), 'error');
  
    }
  }, [searchParams.error, viewContext.notification]);

  function toggleMagicLink(useMagic){

    const f = {...form };
    f.password.type = useMagic ? 'hidden' : 'password';
    f.password.required = useMagic ? false : true;
    f.forgotpassword.type = useMagic ? 'hidden' : 'link';

    setForm(f);
    setUseMagic(useMagic);

  }

  return(
    <Animate type='pop'>
      <Row title='Sign in to amp-up.io'>

        { magicLinkSent ?
          <div style={{ width: '28em', margin: '0 auto' }}>
            <Message 
              title='Check Your Email' 
              type='success'
              text='Please click the link in your email inbox to sign in.'
            /> 
          </div> :
          <Card restrictWidth center>

            <SocialSignin network={['google', 'facebook']} useMagic={ useMagic } showOr />

            { useMagic ?
              <Button 
                text='Use password' 
                color='blue' 
                icon='shield' 
                iconColor='white' 
                rounded iconButton small
                action={ () => { toggleMagicLink(false) }}
              /> :
              /*
              <Button 
                text='Use magic link instead' 
                color='blue' 
                icon='star' 
                iconColor='white' 
                rounded iconButton small
                action={ () => { toggleMagicLink(true) }}
              />
              */''
            }

            <Form
              data={ form }
              method='POST'
              url={ useMagic ? '/v1/auth/magic' : '/v1/auth' }
              buttonText={ useMagic ? 'Send Magic Link' : 'Sign In' }
              callback={ res => {

                useMagic ? setMagicLinkSent(true) : 
                (res.data['2fa_required'] ? History.push(`/signin/otp?token=${res.data.token}`) : authContext.signin(res));

              }}
            />

            <span>Don't have an account? <Link url={'/signup' + (id ? `/user?id=${id}&email=${email}` : '')} text='Sign Up'/></span>

          </Card>
         }
      </Row>
    </Animate>
  );
}
