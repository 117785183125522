/***
*
*   ACCOUNT
*   Index page for account functions
*
**********/

import React, { useContext } from 'react';
import { ViewContext, Animate, Grid, Card, Icon, Link, Loader, useAccount, usePermissions, Button, useAPI } from 'components/lib';

export function Account(props){

  const viewContext = useContext(ViewContext);
  const user = useAPI('/v1/profile');
  const accountData = useAccount();
  const permissionData = usePermissions();

  function editAccount(data){
    const account = accountData.adminAccounts.find(a => a.id === data[0].id);
    if (account && account.permission === 'owner') {

      viewContext.modal.show({
        title: 'Update Account',
        form: {
          id: {
            type: 'hidden',
            value: account.id
          },
          name: {
            label: 'Name',
            type: 'text',
            required: true,
            value: account.name,
            errorMessage: 'Please enter a name'
          }
        },
        buttonText: 'Save',
        url: '/v1/account',
        method: 'PATCH'

      }, (res) => {

        viewContext.notification.show(res.name.value + ' was updated', 'success', true);
        account.name =(res.name.value);

      });
    }
  }
  function checkPermission(account, permission){
    return permissionData.permissions && permissionData.permissions[account.permission][permission]; 
  }
  
  if (user.loading)
    return <Loader />

  return (
    <Animate>
      <h2>Profile</h2>

      <Grid cols='6'>
        
        <Card>

          <Icon image='user'/>
          <h2>Profile</h2>
          <div>Update your profile information</div>
          <Link url='/account/profile'>Update</Link>

        </Card>

        <Card>

          <Icon image='lock'/>
          <h2>Password</h2>
          <div>{ user.data?.has_password ? 'Change your password' : 'Create a password' }</div>
          <Link url='/account/profile/password'>{ user.data?.has_password ? 'Change' : 'Create' }</Link>

        </Card>

        <Card>

          <Icon image='shield'/>
          <h2>Two-Factor Authentication</h2>
          <div>Secure your account</div>
          <Link url='/account/profile/2fa'>{ user.data?.['2fa_enabled'] ? 'Manage' : 'Enable' }</Link>

        </Card>
      </Grid>

      {accountData.adminAccounts?.map (account => {
        return (
          <div key={account.id}>
          <h2>
            {account.name} <small>
            <Button textOnly small text='Edit' action={ editAccount } params={ [ {'id': account.id}] }/><br/>
            {account.permission}</small>

          </h2>
            
          <Grid cols='4'>
            { checkPermission(account,'owner') &&
              <Card inline>

                <Icon image='credit-card'/>
                <h2>Billing</h2>
                <div>Update your plan or credit card</div>
                <Link url='/account/billing'>Manage</Link>

              </Card>
            }

            { checkPermission(account,'admin')&&
              <Card inline>

                <Icon image='users'/>
                <h2>Users</h2>
                <div>Invite users to your account</div>
                <Link url='/account/users'>Add users</Link>

              </Card>
            }

            { checkPermission(account,'admin') &&
              <Card inline>

                <Icon image='folder'/>
                <h2>Projects</h2>
                <div>Manage Assessment projects in your account</div>
                <Link url='/account/projects'>Manage Projects</Link>

              </Card>
            }


          </Grid>
          </div>

        )
      })}

    </Animate>
  )
}