import React, { useEffect, useState } from 'react';
import { Row, Content, Message } from 'components/lib';
import { useLocation } from 'react-router-dom';

export function Error(props) {
  const location = useLocation();
  const [err, setError] = useState({code: 500, message:'Unknown error'});

  useEffect(() => {
    if (location.state.error) {
      setError(location.state.error);
    }
  },[location]);

  return(
    <Row loading={!err?.response}>
      <Content>
        <Message
          type='error'
          title={err?.response?.status}
          text={err?.response?.data?.message}
          buttonText='Go Back'
          buttonLink='/'
        />
      </Content>
    </Row>
  );
}
