/***
*
*   Project NAV
*   Project sub nav that renders different options based
*   on user roles.
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import { useProject, useRoles } from 'components/lib';
import Style from './sub.module.scss';

export function ProjectNav(props){

  const projData = useProject();
  const roleData = useRoles();
  const urlRoot = '/project/' + projData.project?.id;

  if (!projData.project.id) return (
    <nav className={ Style.subnav } loading='false'>
      
      <NavLink exact to='/project/' activeClassName='active'>All Projects</NavLink>

    </nav>

  );
  
  return(
    <nav className={ Style.subnav } loading={(roleData.loading || projData.loading)?'true':'false'}>

      <NavLink exact to={urlRoot} activeClassName='active'>Home</NavLink>
      { roleData.roles && roleData.roles[projData.project?.role]?.manage &&
        <NavLink exact to={urlRoot+'/users' } activeClassName='active'>Users</NavLink> }
      <NavLink to={urlRoot + '/tests'} activeClassName='active'>QTI</NavLink>
      { roleData.roles && roleData.roles[projData.project?.role]?.review &&
        <NavLink exact to={urlRoot + '/review'} activeClassName='active'>Review!</NavLink> }
      { roleData.roles && roleData.roles[projData.project?.role]?.manage &&
        <NavLink exact to={urlRoot + '/api' } activeClassName='active'>Licensing</NavLink> }

    </nav>
  );
}
