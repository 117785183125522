/***
*
*   VIEW
*   The view houses global components that are common to all views
*   (notification, modal), handles errors and renders the layout
*
**********/

import React, { useState, useContext } from 'react';
import { AppLayout, OnboardingLayout, ChromelessLayout, LTILayout, WebsiteLayout, AuthContext, Modal, Notification, Loader, History} from '../lib';


export const ViewContext = React.createContext();

export function View(props){
  const authContext = useContext(AuthContext);
  

  const [notification, setNotification] = useState({ show: 'false', autoclose: true });
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState(false);

  // layouts
  const layouts = {

    app: AppLayout,
    website: WebsiteLayout,
    onboarding: OnboardingLayout,
    chromeless: ChromelessLayout,
    lti: LTILayout
  }

  // set title & layout
  document.title = props.title;
  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display)
    return false;

  function showNotification(text, type, autoclose, format, icon){

    setNotification({

      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose

    });

    if (autoclose) setTimeout(hideNotification, 2000);

  }

  function hideNotification(){

    setNotification({

      text: '',
      type: '',
      show: false,
      format: null,
      icon: null,
      autoclose: true

    });
  }

  function showModal(content, callback){

    let data = {...modal }

    if (content){

      for (let key in content)
        data[key] = content[key];

      data.show = true;
      data.callback = callback;

    }

    setModal(data);

  }

  function hideModal(cancel, res){

    // callback?
    if (!cancel && modal.callback)
      modal.callback(modal.form, res);

    // reset the modal
    setModal({

      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,

    });
  }

  function handleError(err){


    if (err){
      let message = err.response?.data?.message 
                    || err.response?.data?.details?.message 
                    || err.message 
                    || err.toString()
                    || "Unknown Error Occured";

      let status = err.response?.status 
                   || err.request?.status
                   || 500;

      if (err.response){

        if (err.response.data?.name === 'TokenExpiredError') {
          authContext.signout();
          return;
        } 
      }
          
      switch (status){

        case 401:
        case 403:
        case 404:

        History.push({pathname: '/error', state: {error: err}});
        break;

        case 402: // payment required
        History.push('/account/upgrade?plan=' + err.response.data.plan);
        break;

        default:
        console.error(err);
        showNotification(message, 'error', false);
        break;

      }
    }
  }

  const context = {

    notification: {

      show: showNotification,
      hide: hideNotification,
      data: notification,

    },
    modal: {

      show: showModal,
      hide: hideModal,
      data: modal,

    },

    setLoading: state => setLoading(state),
    handleError: handleError

  }

  return (
    <ViewContext.Provider value={ context }>

      { notification.show &&
        <Notification {...notification }/> }

      { loading && 
        <Loader fullScreen /> }

      { modal.show &&
        <Modal {...modal } /> }
      <Layout data={props.data}>
        {props.display}
      </Layout>

    </ViewContext.Provider>
  );
}
