import { Account } from 'views/account';
import { Profile } from 'views/account/profile';
import { Billing } from 'views/account/billing';
import { Upgrade } from 'views/account/upgrade';
import { Users } from 'views/account/users';
import { Password } from 'views/account/password';
import { TwoFA } from 'views/account/2fa';
import { Projects } from 'views/account/projects';

const Routes = [
  {
    path: '/account',
    view: Account,
    permission: 'user',
    title: 'Settings'
  },
  {
    path: '/account/profile',
    view: Profile,
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/profile/password',
    view: Password,
    permission: 'user',
    title: 'Change Password'
  },
  {
    path: '/account/profile/2fa',
    view: TwoFA,
    permission: 'user',
    title: 'Manage 2FA'
  },
  {
    path: '/account/billing',
    view: Billing,
    permission: 'owner',
    title: 'Billing'
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    permission: 'owner',
    title: 'Upgrade'
  },
  {
    path: '/account/users',
    view: Users,
    permission: 'admin',
    title: 'Users'
  },
  {
    path: '/account/projects',
    view: Projects,
    permission: 'admin',
    title: 'Projects'
  },
]

export default Routes;