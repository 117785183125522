/***
*
*   useRoles hook
*   fetch, format and return the user roles
*
**********/

import { useState, useEffect } from 'react';
import { useAPI } from 'components/lib';

export function useRoles(){

  const [state, setState] = useState({ roles: null, options: null, loading: false });
  const roles = useAPI('/v1/platform/roles');

  useEffect(() => {

    setState({ loading: true });

    // format roles
    if (roles.data){
      let retVal = [];
      Object.keys(roles?.data).map(role => {

        retVal.push({ value: role, label: role });
        return retVal;

      });

      setState({
        roles: roles.data , 
        options: retVal,
        loading: false 
      });
    }
  }, [roles]);

  return state;

}
