/***
*
*   CHROMELESS LAYOUT
*   Specialized layout for use in embedded pages (eg: LTI launches)
*
**********/

import { Logo } from 'components/lib';
import React, { Fragment } from 'react';

import './scss/layout.scss';

export function LTILayout(props){

   
  return (
    <Fragment>
      <div id='layout' className='lti'>
      <div className='header'>
        <Logo mark  />
      </div>

      <div id='content'>
        { <props.children {...props.data }/> }
      </div>
      </div>
    </Fragment>
  );
}
