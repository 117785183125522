/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import React, { useState, useEffect } from 'react';
import Axios  from 'axios';
import { Redirect, Route } from 'react-router-dom';

// auth context
export const AuthContext = React.createContext();

const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions');
const roles = require('./roles');

export function AuthProvider(props){
  const searchParams = new URLSearchParams(window.location.search)
  const ltik = searchParams.get('ltik')

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const auth = useAPI(user && !ltik ? '/v1/auth' : null);

  useEffect(() => {

    // update the auth status
    if (!auth.loading && auth.data){

      auth.data.authenticated ? 
        update(auth.data) : signout();

    }
  }, [auth]);

  function signin(res){

    if (res.data){

      localStorage.setItem('user', JSON.stringify(res.data));
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      Event('signin');


      if (!res.data.plan)
        return window.location = '/signup/plan';
    }

    if (res.location) 
      return window.location = res.location;

    return window.location = res.data.onboarded ? '/project' : '/welcome'

  }

  async function signout(url){

    Axios({ method: 'delete', url: '/v1/auth' });
    localStorage.clear();
    if (url) 
      window.location = url;


  }

  const [refreshing, setRefreshing] = useState(false);

  async function setActiveProject(projectId) {

    if (refreshing) return;
    
    setRefreshing(true);

    try {
      let res= {};
      if (projectId !== user.projectId) {
        res = await Axios({

          method: 'post',
          url: '/v1/auth/project',
          data: { projectId: projectId }

        });

      }
 
      res.location = `/project/${projectId}`;
      await signin(res)
    } catch (err) {
      throw(err)
    } finally {
      setRefreshing(false);
    }
    
  }

  function update(data){

    if (localStorage.getItem('user')){

      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data){

        if (Array.isArray(data[key])){
       
          user[key] = data[key]

        }
        else if (typeof data[key] === 'object'){
          for (let innerKey in data[key]){

            user[key][innerKey] = data[key][innerKey]

          }
        }
        else {
          
          user[key] = data[key];

        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);

    }
  }

  let account;
  if (user?.accountId) {
    account = user.accounts.find(acct => acct.id === user.accountId);
  }

  return (
    <AuthContext.Provider value={{

      user: user,
      signin: signin,
      signout: signout,
      update: update,
      setActiveProject: setActiveProject,
      permission: permissions[account?.permission],
      role: roles[user?.role]
    }}

    {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props){

  return <Route {...props} render={ data => {

    // check user
    const user = JSON.parse(localStorage.getItem('user'));
    const path = window.location.pathname;

    if (user?.token){
      let useProject;
      let useAccount;

      if (user.projectId) {
        useProject = user.projects?.find(x => x.id === user.projectId);
        if (!useProject) return <Redirect to='/error/401' />
        useAccount = user.accounts?.find(x => x.id === useProject.account_id)
      } else if (user.accountId) {
        useAccount = user.accounts?.find(x => x.id === user.accountId)
      }
      if ((useAccount && permissions[useAccount.permission][props.permission])
          || (useProject && roles[useProject.role][props.permission])){

        // user has no plan
        if (!user.plan && path !== '/account/profile' && path !== '/signup/plan')
          return <Redirect to='/signup/plan'/>;
  
        // user has no subscription
        if ((user.subscription !== 'active' && user.subscription !== 'trialing') && user.permission !== 'master') {

          if (!path.includes('/account/billing') &&
              !path.includes('/signup/plan') &&
              !path.includes('/account/upgrade') && 
              !path.includes('/account') && 
              !path.includes('/account/profile')){

                return <Redirect to='/account/billing' />

          }
        }

        // user is good
        return <props.render {...props }/>

      }
    }

    // user is not authenticated
    return <Redirect to='/signin' />;

  }}/>
}
