/***
*
*   SOCIAL SIGN IN BUTTONS
*   To sign up/in with Facebook, Google or Microsoft
*
**********/

import React, { useState } from 'react';

import { Button } from 'components/lib';
import Style from './social.module.scss';
import settings from 'settings';

export function SocialSignin(props){

  const [loading, setLoading] = useState(props.network?.map(x => { return { [x]: false }}));
  const env = process.env.REACT_APP_AU_ENV || process.env.NODE_ENV;
  const serverURL = settings[env].server_url;

  // construct query string
  let qs = '';
  if (props.invite) qs = `?invite=${props.invite}`;
  if (props.signup) qs = '?signup=1'

  return (
    <div className={ Style.signinButtons }>
      { props.network?.map(n => {
        
        return (
          <Button  
            key={ n }
            loading={ loading[n] }
            icon={['fab', n]}
            iconPack='fontawesome'
            iconSize='1x'
            iconButton
            className={ Style[n] }
            action={ () => setLoading({ [n]: true }) }
            url={ `${serverURL}/auth/${n}${qs}` }
            text={ `Continue with ${n.charAt(0).toUpperCase() + n.slice(1)}` }
          />
        )
      })}

      { props.showOr && 
        <span className={ Style.or }>or</span> }

    </div>
  );
}
