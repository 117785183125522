/***
*
*   useProject hook
*   fetch, format and return the user permissions
*
**********/

import { useCallback, useContext, useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import Axios  from 'axios';
import { History, ViewContext } from 'components/lib';


export function useLTI(authenticate){
  const context = useRef(useContext(ViewContext));

  const {project_id} = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  const ltik = searchParams.get('ltik');

  const [ltiState, setLtiState] = useState({loading:true});



  const fetch = useCallback(async () => {
    const getResponse = function (state, error) {
      return {
        data: state,
        error: error,
        handleError: function () {
          if (!error) return;
          const status = error.response?.status || 500; 
          switch (status) {
            case 401:
              History.push({pathname: '/error', state: {error: error}});
              //Platform is not registered. Redirect to registration 
              break;
            case 403:
              History.push({pathname: `/lti/${project_id}/courseRegister`, search:`ltik=${ltik}`, state: {error: error}});
              //Platform is not licensed. Redirect to advantage
              break;
            default:
              History.push({pathname: '/error', state:{error: error}})    
          }
        }
      }
    }

    if (authenticate && !ltik) {
      setLtiState(getResponse(false, {message:'LTI Error: ltik is missing from the request'}));
    } else {
      try {
        const state = await Axios({
          url: `/lti/${project_id}/${authenticate?'auth':'info'}`,
          method: 'get',
          ...ltik && {headers: {Authorization: 'Bearer ' + ltik }},
          credentials: 'include' 
        });
        setLtiState(getResponse( state.data.data));
      } catch (err){
        setLtiState(getResponse( false, err));
        context?.current?.handleError(err);
      }
    }
    }, [ltik, project_id, authenticate]);

  useEffect(() => {

    fetch()

  }, [fetch]);
  return ltiState;
}

