/***
*
*   LTI Integration landing page
*   
**********/

import React, { Fragment, useEffect, useState } from 'react';
import { Animate, Card, Grid, useLTI, Button, History } from 'components/lib';
import ReactJson from 'react-json-view';

export function Deeplink(props){
  const ltiInfo = useLTI(true);
  const [proj, setProj] = useState({loading: true});
  
  const searchParams = new URLSearchParams(window.location.search);
  const ltik = searchParams.get('ltik');

  useEffect(() => {
    if (ltiInfo.error) {
      ltiInfo.handleError();
    } else if (!ltiInfo.loading) {
      setProj(ltiInfo.data.project)
    }
  },[ltiInfo ])


  return(
    <Fragment>
      
      <Animate>
        <h2>{proj.name} by amp-up.io</h2>

        <p>Select the type of content below:</p>
        <Grid cols='2'>
          
          <Card>

            <h2>Tests</h2>
            <div>View and Select from available tests</div>
            <br/>
            <div style={{textAlign: 'center'}}>
            <Button text='Go' align='center' action={() => History.push(`deeplink/resources/tests?ltik=${ltik}`)}/>
            </div>

          </Card>

          <Card>
            <h2>Items</h2>
            <div>View and Select from available items</div>
            <br/>
            <div style={{textAlign: 'center'}}>
            <Button text='Go' action={() => History.push(`deeplink/resources/items?ltik=${ltik}`)}/>
            </div>


          </Card>

        </Grid>
            
        <Card title="Debug">
          <ReactJson src={ltiInfo} />
        </Card>
      </Animate>
    </Fragment>
  );
}
