/***
*
*   LTI Platform Advantage Registration
*   
**********/

import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Animate, ViewContext,  Form, Card, Row, History, useLTI} from 'components/lib';
import ReactJson from 'react-json-view';

export function CourseRegister(props){
  const {project_id} = useParams();
  const ltiInfo = useLTI(false);
  const [proj, setProj] = useState();

  
  // context
  const viewContext = useContext(ViewContext);
  

  const defaultForm = {
    
    api_key : {
      label: 'License Key',
      type: 'text',
      errorMessage: 'Enter your amp-up.io License Key'
    }

  }

  
  useEffect(() => {

    if (ltiInfo.data?.project) {
      setProj(ltiInfo.data.project);
    }
  }, [ltiInfo]);


  return(
    <Animate type='pop'>
      <Row title={`${proj?.name} LTI Gateway by amp-up.io`}>


          <Card center>
            <p>
            Welcome to the {proj?.name} LTI Gateway. 
            </p>
            <p>
            Please enter the License Key from the content provider to continue.
            </p>
            <Form
              data={ defaultForm }
              method='POST'
              url={`/lti/${project_id}/authorize`}
              buttonText={ 'Register' }
              callback={ res => {
                if (res.error) {
                  viewContext.notification.show(res.error, 'error'); 
                } else {
                  History.goBack();
                  (window.opener || window.parent).postMessage({subject:'org.imsglobal.lti.close'}, "*");
                }
              }}
            />
          </Card>
          <Card title='Debug'>
            <ReactJson src={ltiInfo} />
          </Card>
      </Row>
    </Animate>
  );
}
