/***
*
*   CONTACT FORM
*   Will send an email notification to you
*
**********/

import React, {Fragment, useState} from 'react';
import { Row, Form, Message, Card, Animate } from 'components/lib';

export function Contact(props){

  const [sent, setSent] = useState(false);

  return(
    <Fragment>
      <Animate type='pop'>
        <Row align='center' title='Contact Us'>

          <Card restrictWidth center>
            { sent ? 
              <Message  
                title='Message Sent'
                type='success'
                text={ `Thank you for your message, we'll be in touch as soon as possible.` }
              />  :
              <Form 
                data={{ 
                  name: {
                    label: 'Your name',
                    type: 'text',
                    required: true,
                    errorMessage: 'Please enter your name'
                  },
                  email: {
                    label: 'Your email',
                    type: 'email',
                    required: true,
                    errorMessage: 'Please enter your email address'
                  },
                  message: {
                    label: 'Message',
                    type: 'textarea',
                    required: true,
                    errorMessage: 'Please enter a message'
                  } 
                }}
                method='POST'
                url='/v1/utility/mail'
                callback={ e => setSent(true) }
                buttonText='Send Message'
              />
            }
          </Card>
        </Row>
      </Animate>
    </Fragment>
  )
}
