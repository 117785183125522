import { Registration } from 'views/lti/registration';
import { Advantage } from 'views/lti/advantage';
import { Landing } from 'views/lti/landing';
import { Deeplink } from 'views/lti/deeplink/index';
import { Resources } from 'views/lti/deeplink/resources';
import { Settings } from 'views/lti/deeplink/settings';
import { CourseRegister} from 'views/lti/courseRegister';

const Routes = [
  {
    path: '/lti/:project_id',
    view: Landing,
    layout: 'chromeless',
    title: 'LTI Welcome'
  },
  {
    path: '/lti/:project_id/registration',
    view: Registration,
    layout: 'lti',
    title: 'LTI Registration'
  },
  {
    path: '/lti/:project_id/advantage',
    view: Advantage,
    layout: 'lti',
    title: 'LTI Advantage Registration'
  },
  {
    path: '/lti/:project_id/courseRegister',
    view: CourseRegister,
    layout: 'lti',
    title: 'LTI Course License Keys'
  },  
  {
    path: '/lti/:project_id/deeplink',
    view: Deeplink,
    layout: 'lti',
    title: 'LTI Content Catalog'
  },
  {
    path: '/lti/:project_id/deeplink/resources/:type',
    view: Resources,
    layout: 'lti',
    title: 'LTI Content Catalog'
  },
  {
    path: '/lti/:project_id/deeplink/settings',
    view: Settings,
    layout: 'lti',
    title: 'LTI Content Catalog'
  }
]

export default Routes;