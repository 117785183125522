/***
*
*   LTI Integration landing page
*   
**********/

import React, {useContext, useState} from 'react';
import { ViewContext, Form, Button } from 'components/lib';


export function SessionControlSettings(props){
  const viewContext = useContext(ViewContext);


/*
 *  - session control: 
 *    - allow_comment: false,
 *    - allow_review: true,
 *    - allow_skipping: true,
 *    - max_attempts: 0,
 *    - show_feedback: false,
 *    - show_solution: false,
 *    - time_limits: {
 *      - min_time: null,               // no limit
 *      - max_time: null,               // no limit
 *      - allow_late_submission: false
 *    - validate_responses: false
 * 
 *  - pnp                                     // Defaults
 *    - textAppearance:                       
 *      - colorStyle: 'COLOR_DEFAULT'         //
 *    - glossaryOnScreen: true,               //
 *    - keywordTranslationLanguage: '',       // Set to ISO 639-1 code when not ''
 *    - extSbacGlossaryIllustration: false,   //
 *    - layoutSingleColumn: false             // unsupported
 */
  function onSubmit(data) {
    const rawData = data
    const returnData = {
      form: rawData,
      pnp: {
        textAppearance: {
          colorStyle: rawData['pnp.textAppearance.colorStyle'],
        },
        glossaryOnScreen: rawData['pnp.glossaryOnScreen'],
        keywordTranslationLanguage: rawData['pnp.keywordTranslationLanguage'],
        extSbacGlossaryIllustration: rawData['pnp.extSbacGlossaryIllustration'],
        layoutSingleColumn: rawData['pnp.layoutSingleColumn']
      },
      sessionControl: {
        allow_comment: rawData['sessionControl.allow_comment'],
        allow_review: rawData['sessionControl.allow_review'],
        allow_skipping: rawData['sessionControl.allow_skipping'],
        max_attempts: rawData['sessionControl.max_attempts'],
        show_feedback: rawData['sessionControl.show_feedback'],
        show_solution: rawData['sessionControl.show_solution'],
        time_limits: {
          min_time: rawData['sessionControl.time_limits.min_time'],
          max_time: rawData['sessionControl.time_limits.max_time'],
          allow_late_submission: rawData['sessionControl.allow_late_submission'],
        },
        validate_responses: rawData['sessionControl.validate_responses'],
      }
    }

    props.callback && props.callback(returnData);

  }

  const [formData, setFormData] =useState({
    sessionControlHeader: {
      type: 'header',
      label: 'Session Control',
      value: false

    },
    'sessionControl.allow_comment': {
      type: 'switch',
      label: 'Allow Comments',
      value: false
    },
    'sessionControl.allow_review': {
      type: 'switch',
      label: 'Allow Review',
      value: false
    },
    'sessionControl.allow_skipping': {
      type: 'switch',
      label: 'Allow Skipping',
      value: false
    },
    'sessionControl.show_feedback': {
      type: 'switch',
      label: 'Show Feedback',
      value: false
    },
    'sessionControl.show_solution': {
      type: 'switch',
      label: 'Show Solution',
      value: false
    },
    'sessionControl.validate_responses': {
      type: 'switch',
      label: 'Allow Comments',
      value: false
    },
    'sessionControl.time_limits.allow_late_submission': {
      type: 'switch',
      label: 'Allow Late Submission',
      value: false
    },
    'sessionControl.time_limits.min_time': {
      type: 'text',
      label: 'Minimum Time (Minutes)',
      value: '0',
    },
    'sessionControl.time_limits.max_time': {
      type: 'text',
      label: 'Maximum Time (Minutes)',
      value: '0',
    },
    'sessionControl.max_attempts': {
      type: 'text',
      label: 'Maximum Number of Attempts',
      value: '0',
    },
    pnpHeader: {
      type: 'header',
      label: 'PNP Settings',
      value: false
    }, 
    'pnp.textAppearance.colorStyle': {
      type: 'select',
      label: 'Colors',
      default: props?.colorClass || 'qti3-player-color-default',
      options: [
        
        {label:'Default', value:'qti3-player-color-default'},
        {label:'Default Reverse', value: 'qti3-player-color-defaultreverse'},
        {label:'High Contrast', value: 'qti3-player-color-blackwhite'},
        {label:'High Contrast Reverse', value: 'qti3-player-color-whiteblack'},
        {label:'Black on Rose', value: 'qti3-player-color-blackrose'},
        {label:'Rose on Black', value: 'qti3-player-color-roseblack'},
        {label:'Medium Gray on Dark Gray', value: 'qti3-player-color-mgraydgray'},
        {label:'Dark Gray on Medium Gray', value: 'qti3-player-color-dgraymgray'},
        {label:'Yellow on Blue', value: 'qti3-player-color-yellowblue'},
        {label:'Blue on Yellow', value:'qti3-player-color-blueyellow'}
      
      ]
    },
    'pnp.keywordTranslationLanguage': {
      type: 'select',
      label: 'Keyword Translation',
      required: true,
      default: 'off',

      options: [
        
        {label:'Off', value:'off'},
        {label:'Arabic', value:'ar'},
        {label:'Burmese', value:'my'},
        {label:'Cantonese', value:'yue'},
        {label:'English', value:'en'},
        {label:'Filipino (Ilokano + Tagalog)', value:'tl'},
        {label:'German', value:'de'},
        {label:'Hmong', value:'hmn'},
        {label:'Japanese', value:'ja'},
        {label:'Korean', value:'ko'},
        {label:'Mandarin', value:'cmn'},
        {label:'Punjabi', value:'pa'},
        {label:'Russin', value:'ru'},
        {label:'Somali', value:'so'},
        {label:'Spanish', value:'es'},
        {label:'Ukrainian', value:'uk'},
        {label:'Vietnamese', value:'vi'}
    ]
    },
    'pnp.glossaryOnScreen': {
      type: 'switch',
      label: 'Glossary',
      value: false
    },
    'pnp.extSbacGlossaryIllustration': {
      type: 'switch',
      label: 'PNP: Illustrated Glossary',
      value: false
    },
    'pnp.layoutSingleColumn': {
       type: 'hidden',
       value: false
    }
  });

  function showModal() {
    viewContext.modal.show({
      title: 'Test Player Settings',
      form: formData,
      buttonText: 'Save Settings', 
    }, (form, res) => {
      onSubmit(res);
      setFormData(form);
    })
  }

  if (props.modal) return (
      <Button
        outline={props.outline}
        small={props.small}
        icon={props.icon}
        text={props.text || 'Change Settings'}
        action={showModal}/>
    );
  else
    return(
      
      <Form
        data={formData}
        buttonText={ props.buttonText }
        disabled={props.disabled}
        back={props.back}
        submitOnChange
        callback={(data) => onSubmit(data.data.data)} />
    );
}
