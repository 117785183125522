/***
*
*   LTI Platform Advantage Registration
*   
**********/

import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import { Animate, ViewContext, Form, Card, Row, Switch, useAPI } from 'components/lib';

export function Advantage(props) {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { project_id } = useParams();
  const ltiInfo = useAPI(`/v1/lti/advantage/${searchParams.token}`);

  // context
  const viewContext = useContext(ViewContext);

  const defaultForm = {
    project_id: {
      type: 'hidden',
      value: project_id,
    },
    openid_configuration: {
      type: 'hidden',
      value: ltiInfo.data?.openid_configuration,
    },
    registration_token: {
      type: 'hidden',
      value: ltiInfo.data?.registration_token
    },

  }
  const apiKeyForm = {
    api_key: {
      label: 'License Key',
      type: 'text',
      errorMessage: 'Enter your amp-up.io License Key'
    }
  }

  const [form, setForm] = useState({ ...defaultForm });


  function toggleLicenseKey(show) {

    let newForm = { ...defaultForm, ...show && apiKeyForm };


    setForm(newForm);
  }

  useEffect(() => {
    if (searchParams.error) {
      viewContext.notification.show(decodeURIComponent(searchParams.error), 'error');
    }

  }, [searchParams.error, viewContext.notification, ltiInfo]);


  return (
    <Animate type='pop'>
      <Row title={`${ltiInfo.data?.project.name} LTI Gateway by amp-up.io`}>


        <Card center>
          <p>
            Welcome to the {ltiInfo.data?.project.name} LTI Gateway.
          </p>
          <p>
            If you have a License Key from the content provider, you can enter it now.
            By proceeding with a License key you will associate your entire platform
            with this key and all contexts in your platform will have access to
            this external tool.
          </p>
          <p>
            If you proceed without a License key, each tool deployment will be required
            to enter a valid License Key.
          </p>
          <Switch
            label='I want to enter my License Key'
            value={false}
            onChange={(name, on) => { toggleLicenseKey(on) }}>

          </Switch>
          <Form
            data={form}
            updateOnChange={true}
            method='POST'
            url={`/v1/lti/advantage`}
            buttonText={'Register'}
            callback={res => {
              if (res.error) {
                viewContext.notification.show(res.error, 'error');
              } else {
                (window.opener || window.parent).postMessage({ subject: 'org.imsglobal.lti.close' }, "*");
              }
            }}
          />
        </Card>
        <Card>
          Debug:<br />
          openid_configuration: {ltiInfo.data?.openid_configuration}<br />
          registration_token: {ltiInfo.data?.registration_token}
        </Card>
      </Row>
    </Animate>
  );
}
