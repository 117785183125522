/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React, { Fragment } from 'react';
import ClassNames from 'classnames';
import { NavLink } from "react-router-dom";
import Style from './sub.module.scss';

export function GuestNav(props){

  return(
    <Fragment>

      <nav className={ ClassNames([Style.subnav, Style.sticky, Style.guest]) }>

          <NavLink exact to='/' activeClassName='active'>About</NavLink>
          <NavLink exact to='/pricing' activeClassName='active'>Pricing</NavLink>
          <NavLink exact to='/contact' activeClassName='active'>Contact Us</NavLink>

      </nav>
      <nav className={ClassNames([Style.subnav])}>&nbsp;</nav>
    </Fragment>
  );
}
