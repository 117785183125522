/***
*
*   API Keys List
*   List & manage the API keys
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext, ViewContext, Animate, Button, Card, Table, 
  BlankSlateMessage, Loader, History, useAPI, CopyToClipboard  } from 'components/lib';
import settings from 'settings';

export function Api(props){
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const project_id = authContext.user?.projectId;

  const fetch = useAPI('/v1/project/api');
  const [keys, setKeys] = useState([]);

  const env = process.env.REACT_APP_AU_ENV || process.env.NODE_ENV;
  
  const ltiToolBaseUrl = settings[env].server_url + `/lti/${project_id}`;
  const ltiToolAdvantageUrl = `${ltiToolBaseUrl}/register`;
  const ltiToolLoginUrl =  `${ltiToolBaseUrl}/login`;
  const ltiToolKeysetUrl =  `${ltiToolBaseUrl}/keyset`;

  useEffect(() => {

    if (fetch?.data?.length)
      setKeys(fetch.data);
 
  }, [fetch]);

  function revoke(data){
    viewContext.modal.show({
      title: 'Revoke License Key ',
      form: {
        active: {
          type: 'hidden',
          value: false,
        }
      },
      text: 'Are you sure you want to revoke this License Key? This action cannot be undone.',
      buttonText: 'Revoke License Key',
      url: `/v1/project/api/${data.id}`,
      destructive: true,
      method: 'PATCH',

      }, () => {

        const state = [...keys];
        state.find(x => x.id === data.id).active = false;
        setKeys(state);

    });
  }

  function del(data){
    viewContext.modal.show({
      title: 'Delete License Key ',
      form: {},
      text: 'Are you sure you want to delete this License Key? This action cannot be undone.',
      buttonText: 'Delete License Key',
      url: `/v1/project/api/${data.id}`,
      destructive: true,
      method: 'DELETE',

      }, () => {

        const state = [...keys];
        state.splice(state.findIndex(x => x.id === data.id), 1);
        setKeys(state);

    });
  }

    
  return (
    <Fragment>

      { fetch.loading ? 
        <Loader /> :
        <Fragment>

          { keys?.length ?
            <Animate>

            <Table 
              title='Manage License Keys'
              addNew={{
                label:'Add New Key',
                action: () => History.push(`/project/${project_id}/api/create`)
              }}
              data={ keys } 
              loading={ fetch.loading }
              show={['name', 'key', 'active']}
              copy={['key']}
              rowAction={{ 
                action:(col) => { History.push(`/project/${project_id}/api/${col}`) },
                col:'id'
              }}
              actions={{ 
                custom: [
                  { icon: 'x-circle', action: revoke, condition: { col: 'active', value: true }},
                ],
                delete: del,
            }}
              badge={{ col: 'active', color: 'green', condition: [

                { value: true, color: 'green' },
                { value: false, color: 'red' }
              
              ]}}
            />
            <Card title="LTI Licensing Information">
             <ul>
                <li>
                  <b>LTI Advantage URL</b>: {ltiToolAdvantageUrl}
                  &nbsp;
                  &nbsp;
                  <CopyToClipboard text={ltiToolAdvantageUrl}
                    onCopy={()=>{viewContext.notification.show('Copied!', 'success',true)}}>
                    <Button  textOnly icon='copy' />
                  </CopyToClipboard>
                </li>
              </ul>
            OR 
             <h3>Manaul Registration details:</h3>
             <ul>
                <li><b>LTI Version:</b> 1.3</li>
                <li><b>LTI Tool URL:</b> {ltiToolBaseUrl}</li>
                <li><b>LTI Tool Login URL:</b> {ltiToolLoginUrl}</li>
                <li><b>Public Key Type:</b> Keyset URL</li>
                <li><b>LTI Tool Keyset URL:</b> {ltiToolKeysetUrl}</li>
                
              </ul>
            </Card>

          </Animate> :

          <BlankSlateMessage 
            title='Create a License Key'
            text={`You haven't created any License keys yet. Would you like to create one now?`}
            buttonText='Create License Key'
            action={ () => History.push(`/project/${project_id}/api/create`) }
            marginTop='4em'
          />
          
          }
        </Fragment>
      }
    </Fragment>
  );
}
