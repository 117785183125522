import React, { Fragment } from 'react';
import {Card, useProject, Loader } from 'components/lib';


export  function Project(props) {
  const projData = useProject();
  
  if (projData.loading) return <Loader/>
  document.title = 'Project: ' + projData.project?.name;

  return (
    <Fragment>

      <Card title="Project ">
            <div>{projData.project?.name}</div>
      </Card>
      
    </Fragment>

  )
}
