/***
*
*   useProject hook
*   fetch, format and return the user permissions
*
**********/

import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'components/lib';

export function useProject(){
  const {project_id} = useParams();
  const authContext = useContext(AuthContext);
  
  if (project_id && project_id !== authContext.user?.projectId) {
    authContext.setActiveProject(project_id);
  }

  const payload = {
    projects: authContext.user?.projects || [],
    project: {}
  }  


  if (project_id) 
    payload.project = authContext.user?.projects?.find(project => project.id === authContext.user?.projectId) || {};
  

  return payload;


}

