/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import { Logo, ProjectHeader, AccountHeader, Footer, GuestNav } from 'components/lib';
import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

import './scss/layout.scss';

export function WebsiteLayout(props){

   
  return (
    <Fragment>

      <div id='layout' className='guest'>

        <div id='left' className='open'>
          <div className='icon' >
            <Logo className='logo' />
          </div>
          <div className={`sidebar open`} >
            <div className='header'>
              <span className='title ampup'>
                <Link to='/'>amp-up.io</Link>
              </span>
            </div>
          </div>
        </div>

        <div id='main'>
          <div className='header'>
            <span className={`
            title
            menu
            ${'left-open'}
        `}>
              <ProjectHeader />
            </span>
            <span className='spacer' />
            <span className='menu'>
              <AccountHeader />
            </span>
          </div>
          <div id='content' className='marginless'>
            <GuestNav sticky/>
            {<props.children {...props.data} />}
            <Footer />
          </div>
        </div>
      </div>            
    </Fragment>
  );
}
