/***
*
*   SIGN UP (user)
*   Signup form for child users
*
**********/

import React, { useContext } from 'react';
import qs from 'qs';
import { Animate, Row, AuthContext, Card, Form, SocialSignin } from 'components/lib';

export function SignupUser(props){

  const context = useContext(AuthContext);
  // invite
  const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const id = searchParams.id;
  const email = searchParams.email;
  
  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card restrictWidth center>

          <SocialSignin network={['google','facebook']} showOr invite={ id } />

          <Form
            data={{
              name: {
                label: 'First Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name'
              },
              email: {
                label: 'Email',
                value: email,
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              invite_id: {
                type: 'hidden',
                value: id
              },
            }}
            url={ `/v1/invites/${id}/accept`} 
            method='POST'
            redirect='/project'
            buttonText='Get Started!'
            callback={ context.signin }
          />

          <span>Already registered? <a href={`/signin?id=${id}&email=${email}`}>Sign In</a></span>

        </Card>
      </Row>
    </Animate>
  );
}
